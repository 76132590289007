<template>
  <section class="invoice-preview-wrapper">
    <b-row
      v-if="Doctor"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <b-col cols="12" md="5">
                <div class="logo-wrapper">
                  <imageLazy
                      :imageStyle="{ 'border-radius': '20px','width': '120px','height':'120px'}"
                      :imageClass="'rounded-full'"
                      :src="baseURL + Doctor.ProfileImagePath"
                       placeHolderType="doctor"
                    />
                  <h3 v-if="!$vs.rtl" class="text-primary mt-1 invoice-logo">
                   
                    {{ Doctor.FullName }}
                  </h3>
                  <h3 v-if="$vs.rtl" class="text-primary mt-1 invoice-logo">
                   
                   {{ Doctor.NameAR }}
                 </h3>
               

                </div>

              <div>
                <h4 class="text-secondary invoice-logo" v-if="Doctor.Country">     {{ $t("Destination") }} : {{ Doctor.Country.Name }}</h4>
               
               <p class="card-text mb-25" >{{ $t("Title") }} : {{ Doctor.Title }}</p>
                
                 <p  v-if="
                  Doctor.Address
                 " class="card-text mb-25">
                    {{ $t("Address") }} : {{ Doctor.Address }}
                 </p>
              </div>
              
            </b-col>

              <!-- Header: Right Content -->
              <b-col cols="12" md="7" class="mt-md-0 mt-2">
                
                <h4 style="font-weight:bold" class="invoice-title">
                  {{ $t("Biography") }}
                </h4>
                <div class="invoice-date-wrapper">
                  <p  class="invoice-date text-primary">
             {{Doctor.Bio}}
            </p>
                </div>
               
              </b-col>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <b-card-body
           
           class="invoice-padding pt-0 m-4"
         >
           <b-row class="invoice-spacing">
             <!-- Col: DoctorSpecialities To -->
             <b-col
             v-if="(Doctor.DoctorSpecialities && Doctor.DoctorSpecialities.length>0)"
             cols="12"
               xl="6"
               class="p-0"
             >
             <h3 class="text-primary invoice-logo">
                 {{ $t("DoctorSpecilties") }}
               </h3>
               <b-row
           class="vx-row ml-10"
           v-for="item in (Doctor.DoctorSpecialities)"
           :key="item.ID"
         >
         <p v-if="item.Specialty" class="circle primaryBackColor mt-1 mr-2"></p>  <span class=" mt-1" v-if="item.Specialty">-{{ item.Specialty.Name }}</span>
          
         </b-row>
             </b-col>

             <!-- Col: Doctor.DoctorExperiences -->
             <b-col
             v-if="(Doctor.DoctorExperiences && Doctor.DoctorExperiences.length>0)"
             cols="12"
               xl="6"
               class="p-0"
             >
                 <h3 class="text-primary invoice-logo">
                   {{ $t("DoctorExperiences") }}
                 </h3>
                 <b-row
                 class="vx-row ml-10"
           v-for="item in Doctor.DoctorExperiences"
           :key="item.ID"
         >
         <p class="circle primaryBackColor mt-1 mr-2"></p>  <span class=" mt-1">-{{ $t("Title") }}: {{ item.TitleEN }} , {{ $t("ReferenceArea") }}:
             {{ item.ReferenceAreaEN }}</span>
         </b-row>
             </b-col>

               <!-- Col: Doctor.DoctorLanguages -->
               <b-col
             v-if="(Doctor.DoctorLanguages && Doctor.DoctorLanguages.length>0)"
             cols="12"
               xl="6"
               class="p-0"
             >
                 <h3 class="text-primary invoice-logo">
                   {{ $t("DoctorLanguages") }}
                 </h3>
                 <b-row
                 class="vx-row ml-10"
           v-for="item in Doctor.DoctorLanguages"
           :key="item.ID"
         >
         <p class="circle primaryBackColor mt-1 mr-2"></p>  <span class=" mt-1">-{{ item.Language.Name }}</span>
         </b-row>
             </b-col>
           </b-row>
         </b-card-body>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: CV -->
         
          <a
          v-if="Doctor.CVPath !=null"
                style="border-radius: 3px"
                :href="baseURL + Doctor.CVPath"
                target="_blank"
              >
              <b-button
          
            variant="outline-primary"
            class="mb-75"
            block
          >
                {{ $t("CV") }}
              </b-button>
              </a>
         
          <div  v-if="showAppointment">
          <!-- Button: OnlineAppointment -->
          <b-button
            variant="primary"
            class="mb-75"
            block
            @click="$router.push('/AppointmentSearch/' + Doctor.ID)"
          >
          {{ $t("OnlineAppointment") }}
          </b-button>

     </div>
        </b-card>
      </b-col>
    </b-row>
    <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger" @click="back()">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
   
  </section>
</template>

<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import { BAlert, BButton, BCard, BCardBody, BCardText, BCol, BLink, BRow, BTableLite } from 'bootstrap-vue';

export default {
  components: {
    imageLazy,
    BAlert, BButton, BCard, BCardBody, BCardText, BCol, BLink, BRow, BTableLite 
  },
  data() {
    return {
      EnglishLan: false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      Doctor: {},
      options: [],
      showAppointment: false,
      radios1: "luis",
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    getDoctorData(Id) {
      debugger;
      this.$vs.loading();
      this.$store.dispatch("DoctorList/GetDoctor", Id).then((res) => {
        debugger;
        if(res.data==undefined)
        this.Doctor = res[0].data.Data;
        else
        this.Doctor = res.data.Data;

      });
    },
  },
  computed: {
    countries() {
      return this.$store.state.CountryList.countries;
    },
  },
  created() {
    this.showAppointment =
      this.$route.params.FromSurgery == undefined ||
      this.$route.params.FromSurgery == false
        ? true
        : false;
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" ||
      localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }

    this.$store.dispatch("CountryList/GetAllCountries");

    var id = this.$route.params.ID;

    if (id) {
      this.getDoctorData(id);
    }
  },
};
</script>
<style>

</style>
